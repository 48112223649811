import React from 'react';
import PropTypes from 'prop-types';
import { storyblokEditable } from 'gatsby-source-storyblok';

import Card from '../Card';

const DynamicCard = ({ blok }) => (
  <Card {...storyblokEditable(blok)} {...blok} />
);

DynamicCard.propTypes = {
  blok: PropTypes.object.isRequired,
};

export default DynamicCard;
