import React from 'react';
import PropTypes from 'prop-types';
import { Box, Heading } from 'grommet';

import ImgixImage from './ImgixImage';
import useRichText from './useRichText';

const Card = ({
  title,
  body,
  image,
  verticalPad = 'none',
  horizontalPad = 'none',
  imageVerticalPad = 'none',
  imageHorizontalPad = 'none',
  ...rest
}) => {
  const richText = useRichText(
    body,
    {},
    { textAlign: 'center', margin: { top: 'none' } }
  );
  return (
    <Box
      pad={{ vertical: verticalPad, horizontal: horizontalPad }}
      {...rest}
      justify="center"
    >
      <Box align="center" justify="center" gap="small">
        <Box
          flex={false}
          alignSelf="center"
          width={{ max: '200px' }}
          pad={{ vertical: imageVerticalPad, horizontal: imageHorizontalPad }}
        >
          <ImgixImage
            fit="contain"
            srcSetOptions={{
              auto: ['format', 'compress'],
            }}
            src={
              image.originalSrc ||
              image.original_src ||
              image.filename ||
              image.src
            }
            alt={image.altText || title}
            a11yTitle={`Photo of ${image.altText || title}`}
          />
        </Box>
        <Box pad="small" flex={false} gap="small">
          <Heading level={5} textAlign="center">
            {title}
          </Heading>
          {richText}
        </Box>
      </Box>
    </Box>
  );
};

Card.propTypes = {
  title: PropTypes.string,
  body: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  image: PropTypes.object,
  verticalPad: PropTypes.string,
  horizontalPad: PropTypes.string,
  imageVerticalPad: PropTypes.string,
  imageHorizontalPad: PropTypes.string,
};

export default Card;
